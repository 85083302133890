<template>
  <div>
    <table v-if="recipe.nutritional_values" class="table">
      <tbody>
        <tr v-for="item in recipe.nutritional_values" :key="item.key">
          <td>
            <span v-if="item.child"> - {{ $t(`RECIPE.${item.key}`) }} </span>
            <span v-else>
              <b>{{ $t(`RECIPE.${item.key}`) }}</b>
            </span>
          </td>
          <td>
            <b-form-group v-if="edit == true" label-for="input-value">
              <b-form-input
                id="input-value"
                v-model="item.value"
                type="number"
                placeholder="Valeur"
              />
            </b-form-group>
            <div v-else class="text-right">
              {{ item.value }}
            </div>
          </td>
          <td>{{ item.unit }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="edit == true">
      <hr />
      <b-button variant="primary" class="mr-1" @click.prevent="save">
        Enregistrer
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'RecipeNutritionalValues',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
  },
  props: {
    recipe: {
      type: Object,
      default() {
        return {}
      },
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      nutritional_values: [],
    }
  },
  created() {
    this.nutritional_values = JSON.parse(
      JSON.stringify(this.recipe.nutritional_values)
    )
  },
  methods: {
    async save() {
      const attributes = { nutritional_values: {} }
      this.recipe.nutritional_values.forEach((item) => {
        attributes.nutritional_values[item.key] = item.value
      })

      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.put(
        `/recipes/${this.recipe.id}/`,
        { attributes },
        {
          headers: {
            'X-Fields': 'id',
            'X-Keys': 'attributes',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      // console.log(JSON.parse(JSON.stringify(resp.data)))
      this.$emit('update:edit', false)
      this.$emit('update', resp.data)
    },
  },
}
</script>
