<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <b-form-group label-for="input-seo_title">
          <validation-provider
            #default="{ errors }"
            name="Ingrédient"
            rules="required"
          >
            <b-form-input
              id="input-seo_title"
              v-model="form.seo_title"
              type="text"
              :state="errors.length > 0 ? false : null"
              placeholder="Meta Title"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-seo_description">
          <b-form-textarea
            id="input-seo_description"
            v-model="form.seo_description"
            rows="4"
            placeholder="Meta Description"
          />
        </b-form-group>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right"></div>
        </div>
      </b-form>
    </validation-observer>
    <!-- <pre>{{ form }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'CopyValue',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    recipeId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        seo_title: null,
        seo_description: null,
      },
    }
  },
  watch: {
    recipeId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.recipeId) {
        const resp = await this.$http.get(`/recipes/${this.recipeId}/`, {
          headers: {
            'X-Fields': Object.keys(this.form).join(','),
          },
        })
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        console.log(success)
        if (success === true && this.recipeId) {
          this.update()
        }
      })
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/recipes/${this.recipeId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
