<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-lg-8">
          <h3>
            <font-awesome-icon
              v-if="recipe.status == 'DISABLED'"
              icon="eye-slash"
              class="text-danger"
            />
            <font-awesome-icon
              v-if="recipe.is_publishable == false"
              icon="flag"
              class="text-danger"
            />
            {{ recipe.label }}
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <a
            :href="recipe.technical_sheet_pdf"
            target="_blank"
            class="btn btn-primary btn-sm mr-1"
            title="Fiche technique au format PDF"
          >
            <feather-icon icon="FileTextIcon" />
          </a>
          <a
            :href="recipe.public_url"
            target="_blank"
            class="btn btn-primary btn-sm mr-1"
            title="Page publique"
          >
            <feather-icon icon="ExternalLinkIcon" />
          </a>
        </div>
      </div>
      <b-tabs v-model="tabIndex" content-class="mt-2">
        <b-tab title="Propriétés" active>
          <div class="row">
            <div class="col-lg-11">
              <div v-if="displayRecipeForm == true" class="m-lg-2 mb-2">
                <h3>Modifier la description de la recette</h3>
                <recipe-form
                  :recipe-id="recipe.id"
                  :display.sync="displayRecipeForm"
                  @updated="onUpdate"
                  @deleted="onDelete"
                  @duplicated="onDuplicate"
                />
              </div>
              <recipe-card v-else :recipe="recipe" />
            </div>
            <div class="col-lg-1">
              <button
                class="btn btn-primary btn-sm"
                @click.prevent="displayRecipeForm = !displayRecipeForm"
              >
                <feather-icon icon="EditIcon" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Valeurs nutritionelles">
          <div v-if="tabIndex == 1" class="row">
            <div class="col-lg-11">
              <recipe-nutritional-values
                :recipe="recipe"
                :edit.sync="editNutritionalValues"
                @update="init"
              />
            </div>
            <div class="col-lg-1">
              <button
                title="Modifier la recette"
                class="btn btn-primary btn-sm"
                @click.prevent="editNutritionalValues = !editNutritionalValues"
              >
                <feather-icon icon="EditIcon" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Photos">
          <div v-if="tabIndex == 2" class="row">
            <div class="col-lg-12">
              <recipe-cover
                :recipe="recipe"
                :edit.sync="editCover"
                @uploaded="init"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="SEO">
          <div v-if="tabIndex == 3" class="row">
            <div class="col-lg-11">
              <div v-if="displayRecipeSeoForm == true" class="m-lg-2 mb-2">
                <h3>Modifier les données SEO</h3>
                <recipe-seo-form
                  :recipe-id="recipe.id"
                  :display.sync="displayRecipeSeoForm"
                  @updated="onUpdate"
                  @deleted="onDelete"
                />
              </div>
              <recipe-seo-card v-else :recipe="recipe" />
            </div>
            <div class="col-lg-1">
              <button
                class="btn btn-primary btn-sm"
                @click.prevent="displayRecipeSeoForm = !displayRecipeSeoForm"
              >
                <feather-icon icon="EditIcon" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ recipe }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h3>Ingrédients</h3>
        </div>
        <div class="col-lg-4 text-right">
          <button
            class="btn btn-primary btn-sm"
            @click.prevent="displayIngredientForm = !displayIngredientForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
        </div>
      </div>
      <div v-if="displayIngredientForm == true" class="m-lg-2 mb-2">
        <h3>Ajouter un ingrédient</h3>
        <recipe-ingredient-form
          :recipe-id="recipe.id"
          :creation="true"
          :display.sync="displayIngredientForm"
          @created="onIngredientCreate"
        />
      </div>
      <p
        v-if="
          recipe.technical_sheet &&
          recipe.technical_sheet.ingredients.length == 0
        "
        class="text-center"
      >
        <em>Pas d’ingredient pour l'instant</em>
      </p>
      <table
        v-if="
          recipe.technical_sheet &&
          recipe.technical_sheet.ingredients.length > 0
        "
        class="table"
      >
        <thead>
          <tr>
            <th>Intitulé</th>
            <th class="px-1 text-center">Code</th>
            <th class="px-1 text-right">Prix ingrédient</th>
            <th class="px-1 text-right">%</th>
            <th class="px-1 text-right">
              Qt./ {{ recipe.technical_sheet.default_quantity }} pers.
            </th>
            <th class="px-1 text-right">
              Coût/ {{ recipe.technical_sheet.default_quantity }} pers.
            </th>
            <th class="px-1 text-right"></th>
          </tr>
        </thead>
        <template v-for="ingredient in recipe.technical_sheet.ingredients">
          <tbody :key="ingredient.recipe_ingredient_id">
            <tr>
              <td>
                <router-link
                  :to="{
                    name: 'ingredient',
                    params: { id: ingredient.ingredient_id },
                  }"
                >
                  <b>{{ ingredient.label }}</b>
                </router-link>
                <p v-if="ingredient.instruction || ingredient.description">
                  <span v-if="ingredient.instruction">
                    {{ ingredient.instruction }}
                    <br />
                  </span>
                  <span>
                    {{ ingredient.description }}
                  </span>
                </p>
              </td>
              <td class="px-1 text-center">
                {{ ingredient.product_code }}
              </td>
              <td class="px-1 text-right">
                {{ ingredient.price | currency }}&nbsp;HT
              </td>
              <td class="px-1 text-right">
                <span v-if="ingredient.is_excluded_from_public_list == true">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                {{ ingredient.quantity_percent }}%
              </td>
              <td class="px-1 text-right">
                {{
                  ingredient.quantity_for_quantity
                    | currency({ fractionCount: 4, symbol: '' })
                }}
                {{ ingredient.unit }}
              </td>
              <td class="px-1 text-right">
                {{
                  ingredient.cost_for_quantity | currency({ fractionCount: 3 })
                }}
                HT
              </td>
              <td class="px-1 text-right">
                <button
                  class="btn btn-primary btn-sm"
                  @click.prevent="
                    editIngredient(ingredient.recipe_ingredient_id)
                  "
                >
                  <feather-icon icon="EditIcon" />
                </button>
              </td>
            </tr>
            <tr v-if="ingredientEdit[ingredient.recipe_ingredient_id] == true">
              <td colspan="4">
                <recipe-ingredient-form
                  :recipe-id="ingredient.recipe_id"
                  :recipe-ingredient-id="ingredient.recipe_ingredient_id"
                  :display.sync="
                    ingredientEdit[ingredient.recipe_ingredient_id]
                  "
                  class="mb-2"
                  @updated="onIngredientUpdate"
                  @deleted="onIngredientDelete"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </table>
      <p
        v-if="
          recipe.technical_sheet &&
          recipe.technical_sheet.ingredients_formated_list
        "
        class="m-1 mt-4"
      >
        {{ recipe.technical_sheet.ingredients_formated_list }}
      </p>
    </b-card>
    <!-- <pre>{{ recipe }}</pre> -->
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import RecipeCard from '@/components/RecipeCard.vue'
import RecipeSeoCard from '@/components/RecipeSeoCard.vue'
import RecipeForm from '@/components/RecipeForm.vue'
import RecipeSeoForm from '@/components/RecipeSeoForm.vue'
import RecipeCover from '@/components/RecipeCover.vue'
import RecipeNutritionalValues from '@/components/RecipeNutritionalValues.vue'
import RecipeIngredientForm from '@/components/RecipeIngredientForm.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    RecipeForm,
    RecipeCard,
    RecipeCover,
    RecipeIngredientForm,
    RecipeNutritionalValues,
    RecipeSeoCard,
    RecipeSeoForm,
  },
  data() {
    return {
      tabIndex: null,
      displayRecipeForm: false,
      displayRecipeSeoForm: false,
      displayIngredientForm: false,
      editNutritionalValues: false,
      editCover: false,
      ingredientEdit: {},
      recipe: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/recipes/${this.$route.params.id}/`, {
        headers: {
          'X-Fields':
            'id,slug,deleted,status,best_seller,public_url,scheme,image_cover,thumb_hero_url,' +
            'seo_title,seo_description,print_code,' +
            'dish,diet,label,label_secondary,seasons_list,category,difficulty,assemblage_difficulty,thumbnails_formats,' +
            'notes,description,nutri_score,eco_score,nutritional_values,' +
            'is_publishable,technical_sheet,recipe_tags,technical_sheet_pdf',
        },
      })
      this.recipe = resp.data
      this.recipe.technical_sheet.ingredients.forEach((ingredient) => {
        this.ingredientEdit[ingredient.recipe_ingredient_id] = false
      })
      if (this.$route.query.edit) {
        this.displayRecipeForm = true
      }
    },
    editIngredient(id) {
      this.ingredientEdit[id] = !this.ingredientEdit[id]
      this.$forceUpdate()
    },
    onUpdate() {
      this.init()
      this.displayRecipeForm = false
    },
    onDuplicate(recipe) {
      console.log(recipe)
      this.displayRecipeForm = false
      // this.$router.push(`/recipes/${recipe.id}`)
      document.location = `/recipes/${recipe.id}?edit=true`
    },
    onDelete() {
      this.displayRecipeForm = false
      this.$router.push('/recipes/')
    },
    onIngredientCreate() {
      this.init()
    },
    onIngredientUpdate() {
      this.init()
    },
    onIngredientDelete() {
      this.init()
    },
  },
}
</script>
