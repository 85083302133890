<template>
  <dl class="row">
    <dt class="col-sm-3 text-right text-muted">Titre SEO</dt>
    <dd class="col-sm-9">
      {{ recipe.seo_title || '-' }}
    </dd>
    <dt class="col-sm-3 text-right text-muted">Description SEO</dt>
    <dd class="col-sm-9">
      {{ recipe.seo_description || '-' }}
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'RecipeCard',
  props: {
    recipe: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
