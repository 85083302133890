<template>
  <dl class="row">
    <dt class="col-sm-3 text-right text-muted">#</dt>
    <dd class="col-sm-9">
      <b><copy-value :value="recipe.id" /></b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Statut</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.deleted === false">
        <b>{{ $t(`RECIPE.${recipe.status}`) }}</b>
      </span>
      <b v-if="recipe.deleted === true" class="text-danger"> Supprimé </b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Meilleure vente</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.best_seller === true">
        <b>Oui</b>
      </span>
      <b v-else> Non </b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Code impression</dt>
    <dd class="col-sm-9">1.{{ recipe.print_code }}</dd>
    <dt class="col-sm-3 text-right text-muted">Destination</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.scheme">
        {{ $t(`RECIPE_SCHEMES.${recipe.scheme}`) }}
      </span>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Type de plat</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.dish">
        {{ $t(`RECIPE_DISHES.${recipe.dish}`) }}
      </span>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Régime</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.diet">
        {{ $t(recipe.diet) }}
      </span>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Catégorie</dt>
    <dd class="col-sm-9">
      <span v-if="recipe.category">
        {{ $t(`RECIPE_CATEGORIES.${recipe.category}`) }}
      </span>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Saison(s)</dt>
    <dd class="col-sm-9">
      <template v-if="recipe.seasons_list">
        <template v-for="(season, key) in recipe.seasons_list">
          <span :key="season">
            {{ $t(season)
            }}<span v-if="key + 1 < recipe.seasons_list.length">, </span>
          </span>
        </template>
      </template>
      <span v-else>-</span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Difficulté cuisson</dt>
    <dd class="col-sm-9">
      {{ recipe.difficulty }}
    </dd>
    <dt class="col-sm-3 text-right text-muted">Difficulté barquettage</dt>
    <dd class="col-sm-9">
      {{ recipe.assemblage_difficulty }}
    </dd>
    <dt class="col-sm-3 text-right text-muted">Description</dt>
    <dd class="col-sm-9">
      {{ recipe.description || '-' }}
    </dd>
    <template v-if="recipe.technical_sheet">
      <dt class="col-sm-3 text-right text-muted">Allergènes</dt>
      <dd class="col-sm-9">
        {{ recipe.technical_sheet.allergens.join(', ') }}
      </dd>
      <dt class="col-sm-3 text-right text-muted">Scores</dt>
      <dd class="col-sm-9">
        Bio {{ recipe.technical_sheet.scores.organic_percent }}% | Local
        {{ recipe.technical_sheet.scores.local_percent }}% | Label
        {{ recipe.technical_sheet.scores.labeled_percent }}%
        <br />
      </dd>
    </template>
    <dt class="col-sm-3 text-right text-muted">Nutri score</dt>
    <dd class="col-sm-9">
      {{ recipe.nutri_score }}
    </dd>
    <dt class="col-sm-3 text-right text-muted">Eco score</dt>
    <dd class="col-sm-9">
      {{ recipe.eco_score }}
    </dd>
    <dt class="col-sm-3 text-right text-muted">Notes</dt>
    <dd class="col-sm-9">
      {{ recipe.notes || '-' }}
    </dd>
    <template v-if="recipe.technical_sheet">
      <dt class="col-sm-3 text-right text-muted">
        Prix de revient pour
        {{ recipe.technical_sheet.default_quantity }}
      </dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.cost_for_quantity | currency }} HT</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Prix de revient unitaire</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.cost_per_unit | currency }} HT</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Pourcentage d’invendus</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.unsold_percent }} %</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Prix par portion vendue</dt>
      <dd class="col-sm-9">
        <b>
          {{ recipe.technical_sheet.cost_per_unit_with_uncost | currency }}
          HT
        </b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Prix de vente</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.public_price | currency }} HT</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Marge brute</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.margin | currency }} HT</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Taux de marge</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.margin_percent }} %</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Coefficient de marge</dt>
      <dd class="col-sm-9">
        <b>{{ recipe.technical_sheet.margin_factor }}</b>
      </dd>
      <dt class="col-sm-3 text-right text-muted">Prix de vente TTC</dt>
      <dd class="col-sm-9">
        <b>
          {{ recipe.technical_sheet.selling_price | currency }} TTC (TVA
          {{ recipe.technical_sheet.vat }} %)
        </b>
      </dd>
    </template>
  </dl>
</template>

<script>
export default {
  name: 'RecipeCard',
  props: {
    recipe: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
