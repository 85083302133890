<template>
  <div class="row">
    <div class="col-md-6">
      <b-img :src="previewCover" fluid thumbnail></b-img>
    </div>
    <div class="col-md-6">
      <b-form-group>
        <b-form-file
          v-model="cover"
          :state="Boolean(cover)"
          browse-text="Sélectionner"
          placeholder="Tranmettre une image au format PNG ou JPG"
        />
        <p v-if="cover" class="text-center mt-3 mb-5">
          <b-button class="btn btn-secondary" @click.prevent="uploadFile()">
            Transmettre
          </b-button>
          <span v-if="onUploadProgress > 0" class="mr-2">
            {{ onUploadProgress }}%
          </span>
        </p>
      </b-form-group>
      <hr />
      <h1 class="h3">Avant de transmettre une photo :</h1>
      <ul>
        <li>Enregistrer l'image pour le web en 1600px x 1600px</li>
        <li>
          En jpg supérieur, 80 de qualité (le poids maximal par image doit être
          de 650ko)
        </li>
        <li>
          S'assurer que le nom du fichier ne comporte aucun caractère spécial
          (pas de virgule, pas de lettres accentuées, pas d'espace, etc.) et
          n'est pas trop long.
        </li>
        <li>Utiliser des minuscules et des tirets '-' pour la lisibilité.</li>
      </ul>
      <p class="mb-0"><strong>Exemple :</strong></p>
      <ul class="font-italic">
        <li>salade-mediterraneenne.jpg</li>
        <li>wok-poulet-sesame-poivrons-ete.jpg</li>
      </ul>

      <!-- <h1 class="h3">Variantes</h1>
      <ul>
        <li v-for="(format, key) in recipe.thumbnails_formats" :key="key">
          {{ format.description }}
          {{ format.w }} x {{ format.h }} <span v-if="format.crop">(crop)</span>
        </li>
      </ul> -->
    </div>
    <!-- <pre>{{ recipe.image_cover }}</pre> -->
    <!-- <pre>{{ recipe.thumb_hero_url }}</pre> -->
    <!-- <pre>{{ recipe.thumbnails_formats }}</pre> -->
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormFile, BImg } from 'bootstrap-vue'

export default {
  name: 'RecipeCover',
  components: {
    BButton,
    BFormGroup,
    BFormFile,
    BImg,
  },
  props: {
    recipe: {
      type: Object,
      default() {
        return {}
      },
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      cover: null,
      uploadPercentage: 0,
      onUploadProgress: 0,
    }
  },
  computed: {
    previewCover() {
      return `${this.recipe.thumb_hero_url}?d=${new Date()}`
    },
  },
  created() {},
  methods: {
    async uploadFile() {
      const formData = new FormData()
      formData.append('image', this.cover)
      // console.log(formData)
      // console.log(this.documents[key])
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/recipes/${this.recipe.id}/upload-cover/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
            'X-Fields': 'id,image_cover',
          },
          onUploadProgress(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100),
              10
            )
          },
        }
      )
      console.log(JSON.parse(JSON.stringify(resp.data)))
      this.onUploadProgress = 0
      this.cover = null
      this.$emit('uploaded', resp.data)
      this.$forceUpdate()
    },
  },
}
</script>
