<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <b-form-group v-if="creation == true" label-for="input-label">
          <vue-typeahead-bootstrap
            ref="typehead"
            v-model="query"
            :data="ingredients"
            :serializer="
              (s) => `${s.label} - #${s.product_code} - ${s.suplier}`
            "
            placeholder="Chercher un ingrédient par nom ou par code"
            @hit="selectIngredient($event)"
          />
        </b-form-group>
        <b-form-group label-for="input-quantity_per_unit">
          <validation-provider
            #default="{ errors }"
            mode="passive"
            name="Quantité par personne"
            rules="required|double:0,dot"
          >
            <b-form-input
              id="input-quantity_per_unit"
              ref="input-quantity_per_unit"
              v-model="form.quantity_per_unit"
              type="number"
              placeholder="Quantité par personne (exemple 0.123, décimale avec un . (point))"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-label">
          <b-form-select
            v-model="form.instruction"
            :options="constants.INSTRUCTIONS"
            placeholder="Instructions"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                -- Liste des instructions --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label-for="input-description" label="Instructions">
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            type="text"
            rows="2"
            max-rows="6"
            placeholder="Instructions pour la préparation"
          />
        </b-form-group>
        <b-form-group id="input-group-is_organic">
          <b-form-checkbox
            v-model="form.is_excluded_from_public_list"
            :value="true"
            :unchecked-value="false"
          >
            Exclure pourcentage des listes publiques
          </b-form-checkbox>
        </b-form-group>

        <!-- <pre>{{ form }}</pre> -->
        <hr />
        <div class="row mt-2">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              <span v-if="recipeIngredientId">Mettre à jour</span>
              <span v-else>Ajouter</span>
            </b-button>
            <b-button
              v-if="creation == true"
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="recipeIngredientId"
              v-b-modal.modal-delete
              variant="danger"
            >
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe-ingredient"
      centered
      hide-header-close
      title="Suprimmer l’ingrédient de la recette"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteRecipeIngredient()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }}</pre> -->
  </div>
</template>

<script>
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import {
  BModal,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'

export default {
  name: 'CopyValue',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VueTypeaheadBootstrap,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    recipeId: {
      default: null,
      type: String,
    },
    recipeIngredientId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      query: null,
      ingredients: [],
      constants: {},
      instruction: null,
      form: {
        recipe_id: null,
        ingredient_id: null,
        label: null,
        is_excluded_from_public_list: null,
        quantity_per_unit: null,
        product_code: null,
        instruction: null,
        description: null,
        suplier: null,
      },
    }
  },
  watch: {
    recipeIngredientId() {
      this.init()
    },
    recipeId() {
      this.form.recipe_id = this.recipeId
    },
    query(newQuery) {
      this.searchDebounce(newQuery, this.searchIngredients)
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'INSTRUCTIONS' },
      })
      this.constants = respConstants.data

      this.form.recipe_id = this.recipeId
      if (this.recipeIngredientId) {
        const resp = await this.$http.get(
          `/recipes/ingredients/${this.recipeIngredientId}/`,
          {
            headers: {
              'X-Fields':
                'quantity_per_unit,description,instruction,is_excluded_from_public_list',
            },
          }
        )
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (
          success === true &&
          this.creation === true &&
          this.form.ingredient_id
        ) {
          this.create()
        } else if (success === true && this.recipeIngredientId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          `/recipes/${this.recipeId}/ingredients/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
        this.resetIngredient()
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/recipes/ingredients/${this.recipeIngredientId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys':
                'quantity_per_unit,instruction,description,is_excluded_from_public_list',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async deleteRecipeIngredient() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.delete(
          `/recipes/ingredients/${this.recipeIngredientId}/`,
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('deleted', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    searchDebounce: _.debounce((term, search) => {
      search(term)
    }, 500),
    async searchIngredients(term) {
      const resp = await this.$http.get('/ingredients/', {
        params: { like_term: term },
        headers: {
          'X-Fields': 'results{id,product_code,label,suplier,unit}',
        },
      })
      this.ingredients = resp.data.results
    },
    resetIngredient() {
      this.form.ingredient_id = null
      this.form.label = null
      this.form.quantity_per_unit = null
      this.form.product_code = null
      this.query = ''
    },
    selectIngredient(ingredient) {
      this.form.ingredient_id = ingredient.id
      this.form.label = ingredient.label
      this.form.quantity_per_unit = null
      this.form.product_code = ingredient.product_code
      this.$nextTick(() => this.$refs['input-quantity_per_unit'].focus())
      this.$nextTick(() => this.$refs['input-quantity_per_unit'].focus())
    },
  },
}
</script>
